// import { sum, map, filter, uniqBy, reject } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// import { apiUrl } from "src/config";

// ----------------------------------------------------------------------

const initialState = {
  errorMessage: "",
  textError: "",
  datas: {
    deviceBarChart: [],
    categories: [],
    connectionState: [],
    latestNotification: [],
    deviceMapChart: [],
  },
};

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // LOADING
    startLoading(state) {
      state.textError = "init";
    },
    stopLoading(state) {
      state.textError = "";
    },

    // GET Datas
    getDatas(state, action) {
      state.datas = { ...state.datas, ...action.payload };
    },
    getTextError(state, action) {
      state.textError = action.payload;
      // state.datas = action.payload;
    },
    // ERROR
    hasError(state, action) {
      state.textError = action.payload.error;
      state.errorMessage = action.payload.message;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getDatas } = slice.actions;
// ----------------------------------------------------------------------

export function getAll(data) {
  return async (dispatch) => {
    dispatch(slice.actions.getDatas(data));
  };
}
