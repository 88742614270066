import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "../redux/store";
import { Link } from "react-router-dom";
import { openExpired, closeExpired } from "../redux/slices/expired";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, ...other } = props;
  return (
    <DialogTitle
      sx={{ m: 0, p: 2, bgcolor: "#f44336", color: "#ffffff" }}
      {...other}
    >
      {children}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  // onClose: PropTypes.func.isRequired,
};

export default function DialogExpired() {
  const dispatch = useDispatch();
  const state = useSelector((defaultState) => defaultState.expired.isOpen);

  useEffect(() => {
    if (state === true) {
      dispatch(openExpired());
    }
  }, [state]);

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={state}
        maxWidth="sm"
        fullWidth
      >
        <BootstrapDialogTitle>Session Expired</BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Please Re-Login</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => dispatch(closeExpired())}
            LinkComponent={Link}
            to="/auth"
            sx={{ color: "#f44336" }}
          >
            Back to Login
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
