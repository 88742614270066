// import { sum, map, filter, uniqBy, reject } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
// ----------------------------------------------------------------------

const initialState = {
  isOpen: false,
};

const slice = createSlice({
  name: "device",
  initialState,
  reducers: {
    isOpen(state, action) {
      state.isOpen = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getDatas } = slice.actions;
// ----------------------------------------------------------------------

export function openExpired() {
  return async (dispatch) => {
    dispatch(slice.actions.isOpen(true));
  };
}

export function closeExpired() {
  return async (dispatch) => {
    dispatch(slice.actions.isOpen(false));
  };
}
