// import { sum, map, filter, uniqBy, reject } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// import { apiUrl } from "src/config";

// ----------------------------------------------------------------------

const initialState = {
  textError: "",
  datas: [],
  location: [],
  center: { lat: 0, lng: 0 },
  drawMode: false,
  deleteCounter: false,
  editCounter: { id: "", location: "", coodinates: [] },
  deleteGeofenceCounter: "",
  cancelUpdateCounter: false,
  editMode: false,
  addPoint: [],
};

const slice = createSlice({
  name: "geofence",
  initialState,
  reducers: {
    // LOADING
    startLoading(state, action) {
      state.textError = "loadingInit";
    },
    stopLoading(state) {
      state.textError = "";
    },
    getLocation(state, action) {
      state.location = action.payload;
    },
    getCenter(state, action) {
      state.center = action.payload;
    },
    getMode(state, action) {
      state.drawMode = action.payload;
    },
    getEditMode(state, action) {
      state.editMode = action.payload;
    },
    getEditCounter(state, action) {
      state.editCounter = action.payload;
    },
    changeDeleteCounter(state) {
      state.deleteCounter = !state.deleteCounter;
    },
    changeDeleteGeofenceCounter(state, action) {
      state.deleteGeofenceCounter = action.payload;
    },
    changeCancelUpdateCounter(state) {
      state.cancelUpdateCounter = !state.cancelUpdateCounter;
    },

    // GET Datas
    getPoint(state, action) {
      state.addPoint = action.payload;
    },
    getTextError(state, action) {
      state.textError = action.payload;
      // state.datas = action.payload;
    },
    getDatas(state, action) {
      state.textError = "200";
      state.datas = action.payload;
    },

    // ERROR
    hasError(state, action) {
      state.textError = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getDatas } = slice.actions;
// ----------------------------------------------------------------------

export function getAll(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/geofence/${id}`);
      //   console.log(response.data);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getPointInPolygon(id, coordinates) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/geofence/point/${id}`, {
        coordinates,
      });
      dispatch(slice.actions.getPoint(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function postOne(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/geofence/${data.id}`, {
        name: data.name,
        zone_name: data.name,
        coordinates: data.coordinates,
        // location: "Kejagung",
      });
      const response = await axios.get(`/geofence/${data.userId}`);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-add"));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function putOne(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/geofence/${data.id}`, {
        name: data.name,
        rid: data.rid,
        zone_id: data.zone_id,
        coordinates: data.coordinates,
        // location: "Kejagung",
      });
      const response = await axios.get(`/geofence/${data.userId}`);

      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-edit"));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function deleteOne(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(
        `/geofence/${data.id}`
        // , {
        //   params: { resourceId: data.resourceId, id: data.zoneid },
        // }
      );

      const response = await axios.get(`/geofence/${data.location}`);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-delete"));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getCenter(location) {
  return async (dispatch) => {
    dispatch(slice.actions.getCenter({ lat: location.lat, lng: location.lng }));
  };
}

export function getIsDrawMode(isDrawMode) {
  return async (dispatch) => {
    dispatch(slice.actions.getMode(isDrawMode));
  };
}

export function getIsEditMode(isEditMode) {
  return async (dispatch) => {
    dispatch(slice.actions.getEditMode(isEditMode));
  };
}

export function getEditCounter(data) {
  return async (dispatch) => {
    dispatch(slice.actions.getEditCounter(data));
  };
}

export function changeDeleteCounter() {
  return async (dispatch) => {
    dispatch(slice.actions.changeDeleteCounter());
  };
}

export function changeDeleteGeofenceCounter(value) {
  return async (dispatch) => {
    dispatch(slice.actions.changeDeleteGeofenceCounter(value));
  };
}

export function changeCancelUpdateCounter() {
  return async (dispatch) => {
    dispatch(slice.actions.changeCancelUpdateCounter());
  };
}
// export function postData(data) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       // Promise.all()
//       await axios.post(`/device`, {
//         name: data.name,

//         serialNumber: data.serialNumber,
//         imei: data.imei,
//         noSimDevice: data.noSimDevice,
//         location: data.location,
//       });
//       const response = await axios.get(`/device`);
//       //   console.log(response.data);
//       dispatch(slice.actions.getDatas(response.data));
//       dispatch(slice.actions.getTextError("200-add"));
//     } catch (error) {
//       //   console.log(error);
//       dispatch(slice.actions.hasError("400"));
//     }
//     dispatch(slice.actions.stopLoading());
//   };
// }
// export function editOne(data) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       await axios.put(`/device/${data.id}`, {
//         name: data.name,
//         serialNumber: data.serialNumber,
//         imei: data.imei,
//         noSimDevice: data.noSimDevice,
//         location: data.location,
//       });
//       const response = await axios.get(`/device`);
//       //   console.log(response.data);
//       dispatch(slice.actions.getDatas(response.data));
//       dispatch(slice.actions.getTextError("200-edit"));
//     } catch (error) {
//       //   console.log(error);
//       dispatch(slice.actions.hasError("400"));
//     }
//     dispatch(slice.actions.stopLoading());
//   };
// }
// export function deleteOne(id) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       await axios.delete(`/device/${id}`);
//       const response = await axios.get(`/device`);
//       //   console.log(response.data);
//       dispatch(slice.actions.getDatas(response.data));
//       dispatch(slice.actions.getTextError("200-delete"));
//     } catch (error) {
//       //   console.log(error);
//       dispatch(slice.actions.hasError("400"));
//     }
//     dispatch(slice.actions.stopLoading());
//   };
// }

// export function getLocation(id) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`/user/location/${id}`);

//       dispatch(slice.actions.getLocation(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//     dispatch(slice.actions.stopLoading());
//   };
// }
// ----------------------------------------------------------------------
