// import { sum, map, filter, uniqBy, reject } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// import { apiUrl } from "src/config";

// ----------------------------------------------------------------------

const initialState = {
  errorMessage: "",
  textError: "",
  datas: [],
};

const slice = createSlice({
  name: "role",
  initialState,
  reducers: {
    // LOADING
    startLoading(state) {
      state.textError = "init";
    },
    stopLoading(state) {
      state.textError = "";
    },

    // GET Datas
    getDatas(state, action) {
      // state.textError = "200";
      // const datas = action.payload;
      // const dataFilter = [];
      // for (const data of datas) {
      //   if (data.nameRole !== "superadmin") {
      //     dataFilter.push(data);
      //   }
      // }
      // state.datas = dataFilter;
      state.datas = action.payload;
    },
    getTextError(state, action) {
      state.textError = action.payload;
      // state.datas = action.payload;
    },
    // ERROR
    hasError(state, action) {
      state.textError = action.payload.error;
      state.errorMessage = action.payload.message;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getDatas } = slice.actions;
// ----------------------------------------------------------------------

export function getAll() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/role`);
      //   console.log(response.data);
      dispatch(slice.actions.getDatas(response.data));
      // dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Request Failed" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

// export function postOne(data) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(`/unitTrail`, {
//         itemId: 22975831,
//         timeFrom: data.dateFrom,
//         timeTo: data.dateTo,
//         // timeFrom: 1640044800,
//         // timeTo: 1640059048,
//       });
//       console.log(response.data);
//       //   const response = await axios.get(`/prisonerShowAll`);
//       dispatch(slice.actions.getDatas(response.data));
//       //   dispatch(slice.actions.getTextError("200-add"));
//     } catch (error) {
//       dispatch(slice.actions.hasError("400"));
//     }
//     dispatch(slice.actions.stopLoading());
//   };
// }

export function putOne(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/role/${data.id}`, {
        nameRole: data.nameRole,
        kitAssignment: data.kitAssignment,
        kitAssignmentCreate: data.kitAssignmentCreate,
        kitAssignmentUpdate: data.kitAssignmentUpdate,
        kitAssignmentDelete: data.kitAssignmentDelete,
        detectionKitManager: data.detectionKitManager,
        historyTrack: data.historyTrack,
        geofence: data.geofence,
        deviceManager: data.deviceManager,
        deviceManagerCreate: data.deviceManagerCreate,
        deviceManagerUpdate: data.deviceManagerUpdate,
        deviceManagerDelete: data.deviceManagerDelete,
        prisonerManager: data.prisonerManager,
        prisonerManagerCreate: data.prisonerManagerCreate,
        prisonerManagerUpdate: data.prisonerManagerUpdate,
        prisonerManagerDelete: data.prisonerManagerDelete,
        prosecutorManager: data.prosecutorManager,
        prosecutorManagerCreate: data.prosecutorManagerCreate,
        prosecutorManagerUpdate: data.prosecutorManagerUpdate,
        prosecutorManagerDelete: data.prosecutorManagerDelete,
        userManagement: data.userManagement,
        userManagementCreate: data.userManagementCreate,
        userManagementUpdate: data.userManagementUpdate,
        userManagementDelete: data.userManagementDelete,
        userRole: data.userRole,
        attorneyManagement: data.attorneyManagement,
        globalSettings: data.globalSettings,
        licenseManager: data.licenseManager,
        log: data.log,

        // kitAssignment: data.kitAssignment,
        // detectionKitManager: data.detectionKitManager,
        // historyTrack: data.historyTrack,
        // prisonerManager: data.prisonerManager,
        // deviceManager: data.deviceManager,
        // geofence: data.geofence,

        // prosecutorManager: data.prosecutorManager,
        // userManagement: data.userManagement,
        // userRole: data.userRole,
        // globalSettings: data.globalSettings,
        // licenseManager: data.licenseManager,
        // log: data.log,
      });
      const response = await axios.get(`/role`);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-edit"));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(slice.actions.hasError({ error: "400", message: error }));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

// export function deleteOne(id) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       await axios.post(`/prisonerDelete`, { id });
//       const response = await axios.get(`/prisonerShowAll`);
//       dispatch(slice.actions.getDatas(response.data));
//       dispatch(slice.actions.getTextError("200-delete"));
//     } catch (error) {
//       dispatch(slice.actions.hasError("400"));
//     }
//     dispatch(slice.actions.stopLoading());
//   };
// }
// ----------------------------------------------------------------------
