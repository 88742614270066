import { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Typography,
  IconButton,
  Box,
  Divider,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "../redux/store";
import {
  removeByIndexNotifGeoFence,
  removeAllNotifGeoFence,
  getIsNotif,
} from "../redux/slices/unit";
import UseLocales from "../hooks/useLocales";

const useStyles = makeStyles((theme) => ({
  dialog: {
    position: "absolute",
    right: 20,
    [theme.breakpoints.only("xs")]: {
      right: 0,
      top: 180,
      maxWidth: "260px",
    },
    top: 64,
    maxWidth: "350px",
    minHeight: "200px",
    maxHeight: "200px",
  },
  rootPaper: {
    borderRadius: "6px !important",
  },
  action: {
    // padding: "0 !important",
    paddingTop: "2px !important",
    paddingBottom: "2px !important",
  },
  bd: {
    position: "static !important",
  },
}));

// const StyledDialog = styled(Dialog)`
//   .MuiDialog-root {
//     position: static !important;
//   }
// `;

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function DialogNotif() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { translate } = UseLocales();
  const state = useSelector((state) => state.unit.notifGeoTest);
  const stateIsNotif = useSelector((state) => state.unit.isNotif);
  // const [open, setOpen] = useState(false);
  // console.log(state);
  useEffect(() => {
    if (state.length > 0) {
      // setOpen(true);
      dispatch(getIsNotif(true));
    } // eslint-disable-next-line
  }, [state.length]);

  return (
    <Dialog
      open={stateIsNotif}
      PaperProps={{
        sx: {
          position: "fixed",
          top: 90,
          right: { xs: 5, sm: 10, md: 10, lg: 10, xl: 10 },
          m: 0,
          maxHeight: "300px",
          maxWidth: { xs: "260px", sm: "400px" },
          zIndex: 2,
        },
      }}
      // onClose={handleClose}
      maxWidth="xs"
      disableEnforceFocus // Let the user focus on elements outside the dialog
      sx={{ position: "initial", top: 0 }}
      // disableBackdropClick
      disablebackdropclick="true"
      fullWidth
      BackdropProps={{
        // sx: { position: "static !important" },
        open: false,
        // classes: { root: classes.bd },
      }}
      scroll="paper"
      // classes={{
      //   paper: classes.dialog,
      //   root: classes.bd,
      // }}
      disableScrollLock={true}
      hideBackdrop={true}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      // PaperProps={{
      //   classes: { rounded: classes.rootPaper },
      // }}
    >
      <DialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
        sx={{ display: "flex", alignItems: "center", paddingTop: "12px" }}
      >
        <Typography sx={{ flex: 1 }} variant="body2" component="div">
          {translate("notification")}
        </Typography>
        {/* <IconButton
          size="small"
          onClick={() => {
            // setOpen(false);
            dispatch(getIsNotif(false));
          }}
        >
          <CloseIcon />
        </IconButton> */}
      </DialogTitle>
      <DialogContent dividers sx={{ paddingTop: "0px" }}>
        <Divider sx={{ paddingBottom: "3px" }} />
        {state.map((value, index) => (
          <Box
            bgcolor={value.jenis === "out" ? "#ffc107" : "#43a047"}
            padding="5px"
            borderRadius="8px"
            marginBottom="5px"
            key={`${index}`}
          >
            <Box display="flex" alignItems="center">
              <Typography
                variant="body1"
                component="div"
                sx={{ color: "#ffffff", fontSize: "0.8rem", flex: 1 }}
              >
                {value.masterKit.device.name}
              </Typography>
              <IconButton
                sx={{ width: "13px", height: "13px" }}
                onClick={() => {
                  dispatch(removeByIndexNotifGeoFence(value.id));
                }}
              >
                <CloseIcon sx={{ width: "13px", height: "13px" }} />
              </IconButton>
            </Box>

            <Typography
              variant="body2"
              component="div"
              sx={{ color: "#ffffff", fontSize: "0.7rem" }}
            >
              {value.value}
            </Typography>
          </Box>
        ))}
        <Divider />
      </DialogContent>
      <DialogActions classes={{ root: classes.action }}>
        <Box display="flex" width="100%" justifyContent="flex-end">
          <Button
            size="small"
            variant="contained"
            color="error"
            endIcon={<DeleteIcon />}
            onClick={() => dispatch(removeAllNotifGeoFence())}
          >
            {translate("btn.deleteAll")}
          </Button>
          <Box paddingLeft="3px">
            <Button
              size="small"
              variant="contained"
              endIcon={<CloseIcon />}
              onClick={() => {
                // setOpen(false);
                dispatch(getIsNotif(false));
              }}
            >
              {translate("btn.close")}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default DialogNotif;
