import { useReducer, createContext } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import Button from "@mui/material/Button";

const initialState = {
  open: false,
};

const handlers = {
  SETOPEN: (state, action) => {
    const { open } = action.payload;
    return {
      ...state,

      open,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const BackdropContext = createContext({
  ...initialState,
  setOpenBackdrop: () => Promise.resolve(),
});

function BackdropProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setOpenBackdrop = async (open) => {
    dispatch({
      type: 'SETOPEN',
      payload: {
        open,
      },
    });
  };
  // console.log(state);
  return (
    <BackdropContext.Provider
      value={{
        // ...state,
        setOpenBackdrop,
      }}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={state.open}
        // onClick={()=>{setOpenBackdrop()}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </BackdropContext.Provider>
  );
}

export { BackdropContext, BackdropProvider };
