// import { sum, map, filter, uniqBy, reject } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// import { apiUrl } from "src/config";

// ----------------------------------------------------------------------

const initialState = {
  errorMessage: "",
  textError: "",
  datas: [],
  editData: {},
  kejari: [],
};

const slice = createSlice({
  name: "jaksa",
  initialState,
  reducers: {
    // LOADING
    startLoading(state) {
      state.textError = "init";
    },
    stopLoading(state) {
      state.textError = "";
    },

    // GET Datas
    getDatas(state, action) {
      // state.textError = "200";
      state.datas = action.payload;
    },
    getKejaris(state, action) {
      // state.textError = "200";
      state.kejari = action.payload;
    },
    getDataEdit(state, action) {
      // state.textError = "200";
      state.editData = action.payload;
    },
    getTextError(state, action) {
      state.textError = action.payload;
      // state.datas = action.payload;
    },
    // ERROR
    hasError(state, action) {
      state.textError = action.payload.error;
      state.errorMessage = action.payload.message;
    },
    // unMount
    unMount(state, action) {
      state.errorMessage = "";
      state.textError = "";
      state.datas = [];
      state.editData = {};
      state.kejari = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getDatas } = slice.actions;
// ----------------------------------------------------------------------

export function getAll() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/master-jaksa/kejati`);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Request Failed" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getAllKejariByUserId(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/master-jaksa/kejari/user/${id}`);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Request Failed" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getAllKejariByKejatiId(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/master-jaksa/kejari/${id}`);
      dispatch(slice.actions.getKejaris(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Request Failed" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getOneByKejati(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/master-jaksa/kejati/${id}`);
      dispatch(slice.actions.getDataEdit(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Request Failed" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function postOne(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/master-jaksa`, {
        kejati: data.kejati,
        kejari: data.kejari,
      });
      const response = await axios.get(`/master-jaksa/kejati`);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-add"));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(slice.actions.hasError({ error: "400", message: error }));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function putOne(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/master-jaksa/${data.kejati.id}`, {
        kejati: data.kejati,
        kejari: data.kejari,
        delete: data.delete,
      });
      const response = await axios.get(`/master-jaksa/kejati`);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-edit"));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(slice.actions.hasError({ error: "400", message: error }));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function deleteOne(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/master-jaksa/${id}`);
      const response = await axios.get(`/master-jaksa/kejati`);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-delete"));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Failed to Delete" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function unMount() {
  return async (dispatch) => {
    dispatch(slice.actions.unMount());
  };
}
// ----------------------------------------------------------------------
