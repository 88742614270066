import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// ----------------------------------------------------------------------
GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, role } = useAuth();

  function getPath(role) {
    let path = "";
    if (role.detectionKitManager) {
      path = "/detection-kit-manager";
    } else if (role.kitAssignment) {
      path = "/kit-assignment";
    } else if (role.historyTrack) {
      path = "/history-track";
    } else if (role.prisonerManager) {
      path = "/prisioner-manager";
    } else if (role.prosecutorManager) {
      path = "/prosecutor-manager";
    } else if (role.userManagement) {
      path = "/user-management";
    } else if (role.userRole) {
      path = "/user-role";
    } else if (role.globalSettings) {
      path = "/global-settings";
    } else {
      path = "/log";
    }
    return path;
  }

  if (isAuthenticated && role) {
    return <Navigate to={getPath(role)} replace />;
  }

  return <>{children}</>;
}
