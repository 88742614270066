import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
// import jwtDecode from 'jwt-decode';
// utils
import axios from "../utils/axios";
import { setSession, jwtDecode } from "../utils/jwt";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  role: null,
  error: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, role } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      role,
    };
  },
  LOGIN: (state, action) => {
    const { user, role } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      role,
      error: null,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    role: null,
    error: null,
  }),
  ROLE: (state, action) => ({
    ...state,
    role: action.payload,
  }),
  ERROR: (state, action) => {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      role: null,
      error: action.payload.error,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  changeRole: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken) {
          setSession(accessToken);
          const decode = jwtDecode(accessToken);
          // const decode = "";
          // const { user } = response.data;
          const responseUser = await axios.get(`/user/${decode.id}`);
          const responseRole = await axios.get(
            `/role/${responseUser.data.roleName}`
          );
          const splitLocation = responseUser.data.location.split(",");

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user: {
                id: decode.id,
                name: responseUser.data.name,
                username: responseUser.data.username,
                profile: responseUser.data.profile,
                iat: responseUser.data.lastLogin,
                location: responseUser.data.location,
                locationArray: splitLocation,
              },
              role: responseRole.data,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
              role: null,
              error: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
            role: null,
            error: err,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (username, password, token) => {
    try {
      const response = await axios.post("/auth", {
        username,
        password,
        token,
      });
      const accessToken = response.data;
      const decode = jwtDecode(accessToken);
      // const decode = "";
      setSession(accessToken);
      const splitLocation = decode.location.split(",");
      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            id: decode.id,
            name: decode.name,
            username: decode.username,
            iat: decode.iat,
            locationArray: splitLocation,
            location: decode.location,
            profile: decode.profile,
          },
          role: decode.role,
        },
      });
    } catch (error) {
      dispatch({
        type: "ERROR",
        payload: {
          error,
        },
      });
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  const changeRole = async (role) => {
    dispatch({
      type: "ROLE",
      payload: role,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        changeRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
