import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import dashboard from "./slices/dashboard";
import detectionKit from "./slices/detectionKit";
import prisioner from "./slices/prisioner";
import prosecutor from "./slices/prosecutor";
import historyTrack from "./slices/historyTrack";
import role from "./slices/role";
import unit from "./slices/unit";
import kit from "./slices/kit";
import license from "./slices/license";
import user from "./slices/user";
import device from "./slices/device";
import masterKit from "./slices/masterKit";
import geofence from "./slices/geofence";
import globalSetting from "./slices/globalSetting";
import whatsapp from "./slices/whatsapp";
import jaksa from "./slices/jaksa";
import pod from "./slices/pod";
import log from "./slices/log";
import notif from "./slices/notif";

import expired from "./slices/expired";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  detectionKit,
  dashboard,
  prisioner,
  prosecutor,
  historyTrack,
  role,
  unit,
  kit,
  license,
  user,
  device,
  masterKit,
  geofence,
  globalSetting,
  whatsapp,
  jaksa,
  log,
  pod,
  notif,
  expired,
});

export { rootPersistConfig, rootReducer };
