// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: "light",
  themeDirection: "ltr",
  themeColorPresets: "default",
  themeLayout: "vertical",
  themeStretch: false,
};

// export const apiUrl = "http://localhost:3001";
// export const HOST_API = "http://localhost:3001";
export const apiUrl = "https://bundar.cyberninja.cloud";
export const HOST_API = "https://bundar.cyberninja.cloud";
export const apiGoogleMap = "AIzaSyCcUa_2Vome_YVTzgG6ZWFqRaXtkDpFM6A";
export const keyMui =
  "3b2db06a035a435ec8e13cc65e9e237dT1JERVI6MjQ0MjIsRVhQSVJZPTE2NTE2NTIwNzAwMDAsS0VZVkVSU0lPTj0x";
export const reCaptchav2SiteKey = "6LfGMbYdAAAAAERehwPGi-DDkldglVfa_m76ZIAh";
export const mapBoxAccessToken =
  "pk.eyJ1IjoiZHJhbWRhbmkiLCJhIjoiY2tuNnR5bzdoMDlyMzJxbXp0enlxbWV3diJ9.u63fK50My5pK_-IK00XtwA";
export const MAPBOX_API =
  "pk.eyJ1IjoiZHJhbWRhbmkiLCJhIjoiY2tuNnR5bzdoMDlyMzJxbXp0enlxbWV3diJ9.u63fK50My5pK_-IK00XtwA";
