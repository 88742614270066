// import { sum, map, filter, uniqBy, reject } from "lodash";
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// import { apiUrl } from "src/config";

// ----------------------------------------------------------------------

const initialState = {
  errorMessage: '',
  textError: '',
  datas: [],
};

const slice = createSlice({
  name: 'kit',
  initialState,
  reducers: {
    // LOADING
    startLoading(state) {
      state.textError = 'init';
    },
    stopLoading(state) {
      state.textError = '';
    },

    // GET Datas
    getDatas(state, action) {
      // state.textError = "200";
      state.datas = action.payload;
    },
    getTextError(state, action) {
      state.textError = action.payload;
      // state.datas = action.payload;
    },
    // ERROR
    hasError(state, action) {
      state.textError = action.payload.error;
      state.errorMessage = action.payload.message;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getDatas } = slice.actions;
// ----------------------------------------------------------------------

export function getAll() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/kit`);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError({ error: '400', message: 'Request Failed' }));
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function postOne(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/kit`, {
        prisonerName: data.prisonerName,
        prisonerId: data.prisonerId,
        uniqueId: data.uniqueId,
        deviceType: data.deviceType,
        phoneNumber: data.phoneNumber,
        location: data.location,
      });
      const response = await axios.get(`/kit`);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError('200-add'));
    } catch (error) {
      dispatch(slice.actions.hasError({ error: '400', message: error }));
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function putOne(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/kit/${data.id}`, {
        prisonerName: data.prisonerName,
        prisonerId: data.prisonerId,
        uniqueId: data.uniqueId,
        deviceType: data.deviceType,
        phoneNumber: data.phoneNumber,
        location: data.location,
      });
      const response = await axios.get(`/kit`);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError('200-edit'));
    } catch (error) {
      dispatch(slice.actions.hasError({ error: '400', message: error }));
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function deleteOne(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/kit/${id}`);
      const response = await axios.get(`/kit`);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError('200-delete'));
    } catch (error) {
      dispatch(slice.actions.hasError({ error: '400', message: 'Failed to Delete' }));
    }
    dispatch(slice.actions.stopLoading());
  };
}
// ----------------------------------------------------------------------
