import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Avatar,
  Divider,
  Typography,
  Stack,
  Dialog,
  Link,
  Tooltip,
  IconButton,
} from "@mui/material";
// utils
import cssStyles from "../utils/cssStyles";
// components
import Image from "../components/Image";
import SvgIconStyle from "../components/SvgIconStyle";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CloseIcon from "@mui/icons-material/Close";
// ----------------------------------------------------------------------

const OverlayStyle = styled("div")(({ theme }) => ({
  ...cssStyles().bgBlur({ blur: 2, color: theme.palette.primary.darker }),
  top: 0,
  zIndex: 8,
  content: "''",
  width: "100%",
  height: "100%",
  position: "absolute",
}));

// ----------------------------------------------------------------------

DialogProfile.propTypes = {
  user: PropTypes.object.isRequired,
};

export default function DialogProfile(props) {
  const { open, children, size, close, user, whatsapp } = props;
  return (
    <Dialog
      fullWidth
      maxWidth={size}
      open={open}
      onClose={close}
      // PaperProps={{ sx: { padding: "0px" } }}
      scroll="paper"
    >
      <Box sx={{ position: "relative" }}>
        <IconButton
          sx={{
            position: "absolute",
            zIndex: 11,
            right: 0,
            top: 0,
            color: "#eceff1",
          }}
          onClick={close}
        >
          <CloseIcon />
        </IconButton>

        <Avatar
          alt="user"
          src={user.profile ? user.profile : ""}
          sx={{
            width: 100,
            height: 100,
            zIndex: 11,
            left: 0,
            right: 0,
            bottom: -32,
            mx: "auto",
            position: "absolute",
          }}
        />
        <OverlayStyle />
        <Image
          src="/static/brand/kejaksaan_cover.svg"
          alt={"cover"}
          ratio="21/9"
        />
      </Box>

      <Box display="flex" justifyContent="center">
        <Typography variant="subtitle1" sx={{ mt: 6 }}>
          {user.name}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {user.role}
        </Typography>
      </Box>
      {whatsapp && (
        <Stack alignItems="center">
          {/* <SocialsButton initialColor sx={{ my: 2.5 }} /> */}

          <Link href={whatsapp} target="_blank">
            <Tooltip title="" placement="top">
              <IconButton
                color="inherit"
                // sx={{
                //   ...(initialColor && {
                //     color: socialColor,
                //     '&:hover': {
                //       bgcolor: alpha(socialColor, 0.08),
                //     },
                //   }),
                //   ...sx,
                // }}
                // {...other}
              >
                <WhatsAppIcon />
              </IconButton>
            </Tooltip>
          </Link>
        </Stack>
      )}
      <Divider sx={{ borderStyle: "dashed" }} />
      {children}
    </Dialog>
  );
}
