// import { sum, map, filter, uniqBy, reject } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// import { apiUrl } from "/config";

// ----------------------------------------------------------------------

const initialState = {
  textError: "",
  datas: [],
  events: [],
  location: [],
  initLocation: [],

  isOpenDialogTable: false,
  locationLabel: "",
  tableData: [],
  openDialogForm: {
    edit: false,
    delete: false,
    add: false,
  },
  dataRowForm: {},
  popUpData: {},
  kit: [],
  centerCounter: { lat: "", lon: "" },
};

const slice = createSlice({
  name: "detectionKit",
  initialState,
  reducers: {
    // LOADING
    startLoading(state) {
      state.textError = "loading";
    },
    stopLoading(state) {
      state.textError = "";
    },
    getTextError(state, action) {
      state.textError = action.payload;
      // state.datas = action.payload;
    },
    getCenterCounter(state, action) {
      state.centerCounter = { ...state.centerCounter, ...action.payload };
      // state.datas = action.payload;
    },
    // GET Datas
    getDatas(state, action) {
      // state.textError = "200";
      state.datas = action.payload;
      const event = [];
      action.payload.forEach((value) => {
        value.data.forEach((valueData) => {
          event.push({ location: value.location, ...valueData });
        });
      });
      state.events = event;
    },
    changeEvent(state, action) {
      // state.textError = "200";
      const events = state.events;
      action.payload.forEach((value) => {
        if (value.d.pos) {
          const index = events.findIndex(
            (valueIndex) => valueIndex.id === value.i
          );
          if (value.d.pos.x && value.d.pos.y) {
            events[index].lat = value.d.pos.y;
            events[index].lon = value.d.pos.x;
          }
          if (value.d.t) {
            events[index].lu = value.d.t;
          }
        }
      });
      state.events = events;
    },
    updateLocation(state, action) {
      const events = state.events;

      // const index = events.findIndex((valueIndex) => valueIndex.id === action.payload.id);
      // console.log(index);
      if (action.payload.lat && action.payload.lon) {
        events[action.payload.index].lat = action.payload.lat;
        events[action.payload.index].lon = action.payload.lon;
      }
      if (action.payload.time) {
        events[action.payload.index].lu = action.payload.time;
      }

      state.events = events;
    },
    getDatasByLocation(state, action) {
      // state.textError = "200";
      // const index = state.tableData.findIndex(
      //   (data) => data.location === action.payload.location
      // );
      let index;
      const newDatas = [];
      state.datas.forEach((value, indexData) => {
        if (value.location === action.payload.location) {
          newDatas.push(action.payload);
          index = indexData;
        } else {
          newDatas.push(value);
        }
      });
      state.datas = newDatas;

      state.tableData = newDatas[index] ? newDatas[index].data : [];
      // state.datas[index] = action.payload;
    },
    // ERROR
    hasError(state, action) {
      state.textError = action.payload;
    },
    getLocation(state, action) {
      state.location = action.payload;
    },
    getLocationAll(state, action) {
      const location = [];
      for (const data of action.payload) {
        location.push(data.location);
      }
      state.initLocation = location;
    },
    openDialogTable(state, action) {
      if (action.payload.status === true) {
        state.tableData = action.payload.data;
        state.locationLabel = action.payload.locationLabel;
        state.isOpenDialogTable = true;
      } else {
        state.isOpenDialogTable = false;
        state.tableData = [];
        state.locationLabel = "";
      }
    },
    openTable(state, action) {
      state.isOpenDialogTable = action.payload;
    },
    closeDialogForm(state) {
      state.openDialogForm = {
        edit: false,
        delete: false,
        add: false,
      };
    },
    openDialogForm(state, action) {
      if (action.payload.type === "add") {
        state.isOpenDialogTable = false;
        state.openDialogForm.add = true;
      } else if (action.payload.type === "edit") {
        state.isOpenDialogTable = false;
        state.openDialogForm.edit = true;
        state.dataRowForm = action.payload.dataRow;
      } else if (action.payload.type === "delete") {
        state.isOpenDialogTable = false;
        state.openDialogForm.delete = true;
        state.dataRowForm = action.payload.dataRow;
      }
    },
    unmount(state) {
      state.textError = "";
      state.datas = [];
      state.location = [];
      state.isOpenDialogTable = false;
      state.locationLabel = "";
      state.tableData = [];
      state.openDialogForm = {
        edit: false,
        delete: false,
        add: false,
      };
      state.dataRowForm = {};
    },
    setPoupData(state, action) {
      state.popUpData = { ...state.popUpData, ...action.payload };
    },
    getKit(state, action) {
      // state.textError = "200";
      state.kit = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getDatas } = slice.actions;
// ----------------------------------------------------------------------

export function getAll() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/unitShowAll`);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getAllKit() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/kit`);
      dispatch(slice.actions.getKit(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getAllByLocation(id, location) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/master-kit/detection/${id}`, {
        location,
      });
      dispatch(slice.actions.getDatas(response.data));
      // dispatch(slice.actions.getLocationAll(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400-get"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getAllByLocationUser(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/master-kit/detection/${id}`
        // , {
        //   location,
        // }
      );
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getLocationAll(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400-get"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getLocation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/user/location/${id}`);

      dispatch(slice.actions.getLocation(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError(error));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function postOne(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/unit`, {
        name: data.name,
        uniqueId: data.uniqueId,
        phoneNumber: data.phoneNumber,
        location: data.location,
      });
      const response = await axios.get(`/unit/location`, {
        params: { location: data.location },
      });

      dispatch(slice.actions.getDatasByLocation(response.data));
      dispatch(slice.actions.getTextError("200-add"));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function putOne(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/unit/${data.id}`, {
        name: data.name,
        phoneNumber: data.phoneNumber,
        uniqueId: data.uniqueId,
        location: data.location,
      });
      // const response = await axios.get(`/unitShowAll`);
      // dispatch(slice.actions.getDatas(response.data));
      const response = await axios.get(`/unit/location`, {
        params: { location: data.location },
      });
      dispatch(slice.actions.getDatasByLocation(response.data));
      dispatch(slice.actions.getTextError("200-edit"));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function deleteOne(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/unit/${data.id}`, {
        params: {
          location: data.location,
        },
      });
      const response = await axios.get(`/unit/location`, {
        params: { location: data.location },
      });
      dispatch(slice.actions.getDatasByLocation(response.data));
      // const response = await axios.get(`/unitShowAll`);
      // dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-delete"));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function openDialogTable(data) {
  return async (dispatch) => {
    dispatch(
      slice.actions.openDialogTable({
        status: data.status,
        data: data.data,
        locationLabel: data.label,
      })
    );
  };
}

export function closeDialogForm() {
  return async (dispatch) => {
    dispatch(slice.actions.closeDialogForm());
  };
}

export function openDialogForm(data) {
  return async (dispatch) => {
    dispatch(
      slice.actions.openDialogForm({ type: data.type, dataRow: data.dataRow })
    );
  };
}

export function getCenterCounter(data) {
  return async (dispatch) => {
    dispatch(slice.actions.getCenterCounter(data));
  };
}

export function openTable(state) {
  return async (dispatch) => {
    dispatch(slice.actions.openTable(state));
  };
}

export function changeEvent(event) {
  return async (dispatch) => {
    dispatch(slice.actions.changeEvent(event));
  };
}

export function updateLocation(event) {
  return async (dispatch) => {
    dispatch(slice.actions.updateLocation(event));
  };
}

export function popUpData(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/kit/prisoner/${data.uid}`);

      dispatch(
        slice.actions.setPoupData({
          [data.id]: { device: data, prisoner: response.data },
        })
      );
      dispatch(slice.actions.getTextError("200-popup"));
    } catch (error) {
      if (error === "expired") {
        dispatch(slice.actions.hasError("401"));
      } else {
        dispatch(slice.actions.hasError("400"));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function unMount() {
  return async (dispatch) => {
    dispatch(slice.actions.unmount());
  };
}
// ----------------------------------------------------------------------
