// import { sum, map, filter, uniqBy, reject } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
// utils
import axios from "../../utils/axios";
import { epochToDate } from "../../utils/epoch";
// import { apiUrl } from "src/config";

// ----------------------------------------------------------------------

const initialState = {
  errorMessage: "",
  textError: "",
  dataBounds: [],
  dataUnits: [],
  dataTrails: [],
  datas: [],
  removeLayer: "",
  hideShow: "",
  play: "",
  nextCounter: "",
  prevCounter: "",
  stop: "",
};

const slice = createSlice({
  name: "historyTrack",
  initialState,
  reducers: {
    // LOADING
    startLoading(state) {
      state.textError = "init";
    },
    stopLoading(state) {
      state.textError = "";
    },

    // GET Datas
    getDatas(state, action) {
      // state.textError = "200";
      // state.dataBounds = { id: action.payload.id, data: action.payload.data.bounds };
      // state.dataTrails = { id: action.payload.id, data: action.payload.data.trail };
      // state.dataUnits = { id: action.payload.id, data: action.payload.data.units };
      const getData = state.datas;
      getData.push({
        id: `${action.payload.id}-${uuidv4()}`,
        idData: action.payload.id,
        data: action.payload.data,
        device: action.payload.device,
        date: action.payload.date,
        routeColor: action.payload.routeColor,
      });
      state.datas = getData;
    },
    getTextError(state, action) {
      state.textError = action.payload;
      // state.datas = action.payload;
    },
    // ERROR
    hasError(state, action) {
      state.textError = action.payload.error;
      state.errorMessage = action.payload.message;
    },
    removeLayer(state, action) {
      state.removeLayer = action.payload;
      const datas = state.datas;
      const index = datas.findIndex((value) => value.id === action.payload);
      state.datas.splice(index, 1);
    },
    hideShow(state, action) {
      const data = action.payload;
      state.hideShow = data;
    },
    play(state, action) {
      state.play = action.payload;
    },
    next(state, action) {
      state.nextCounter = action.payload;
    },
    prev(state, action) {
      state.prevCounter = action.payload;
    },
    stop(state, action) {
      state.stop = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getDatas } = slice.actions;
// ----------------------------------------------------------------------

// export function getAll() {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`/prisonerShowAll`);
//       //   console.log(response.data);
//       dispatch(slice.actions.getDatas(response.data));
//       // dispatch(slice.actions.getDatas(response.data));
//     } catch (error) {
//       //   console.log(error);
//       dispatch(slice.actions.hasError("400"));
//     }
//     dispatch(slice.actions.stopLoading());
//   };
// }

export function postOne(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/unit/trail`, {
        itemId: data.unit,
        timeFrom: data.dateFrom,
        timeTo: data.dateTo,
        location: data.location,
        // timeFrom: 1640044800,
        // timeTo: 1640059048,
      });

      if (response.data === 0) {
        dispatch(
          slice.actions.hasError({ error: "400", message: "No Data Found" })
        );
      } else {
        const date = `${format(
          epochToDate(data.dateFrom),
          "dd/MM/yyyy HH:mm"
        )} - ${format(epochToDate(data.dateTo), "dd/MM/yyyy HH:mm")}`;
        // console.log(response.data);
        //   const response = await axios.get(`/prisonerShowAll`);
        dispatch(
          slice.actions.getDatas({
            id: data.unit,
            data: response.data,
            device: data.label,
            date,
            routeColor: data.routeColor,
          })
        );
        dispatch(slice.actions.getTextError("200-add"));
      }
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Request Failed" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

// export function putOne(data) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       await axios.post(`/prisonerEdit`, data);
//       const response = await axios.get(`/prisonerShowAll`);
//       dispatch(slice.actions.getDatas(response.data));
//       dispatch(slice.actions.getTextError("200-edit"));
//     } catch (error) {
//       dispatch(slice.actions.hasError("400"));
//     }
//     dispatch(slice.actions.stopLoading());
//   };
// }

export function removeLayer(id) {
  return async (dispatch) => {
    dispatch(slice.actions.removeLayer(id));
  };
}

export function hideShowLayer(data) {
  return async (dispatch) => {
    dispatch(slice.actions.hideShow(data));
  };
}

export function play(data) {
  return async (dispatch) => {
    dispatch(slice.actions.play(data));
  };
}

export function next(data) {
  return async (dispatch) => {
    dispatch(slice.actions.next(data));
  };
}

export function prev(data) {
  return async (dispatch) => {
    dispatch(slice.actions.prev(data));
  };
}

export function stop(data) {
  return async (dispatch) => {
    dispatch(slice.actions.stop(data));
  };
}

// ----------------------------------------------------------------------
