import { useEffect } from "react";
import { io } from "socket.io-client";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import Settings from "./components/settings";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import ThemeColorPresets from "./components/ThemeColorPresets";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import NotistackProvider from "./components/NotistackProvider";
import { useDispatch } from "./redux/store";
import { getEvent, getNotifGeoFence } from "./redux/slices/unit";
import { getToken } from "./redux/slices/whatsapp";
import { getAll as getAllDashboard } from "./redux/slices/dashboard";
import { HOST_API } from "./config";
import DialogNotif from "./components/DialogNotif";
import DialogIdle from "./components/DialogExpired";

import useAuth from "./hooks/useAuth";
// ----------------------------------------------------------------------
const socket = io(HOST_API);

export default function App() {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useAuth();

  useEffect(() => {
    if (user) {
      socket.on("position", (event) => {
        if (event.data.events && event.data.events.length > 0) {
          // // if (user && user.locationArray) {
          const index = user?.locationArray.indexOf(event.location);
          if (index > -0) {
            dispatch(getEvent(event.events));
          }
          // // }
        }
      });
    }
  }, [user]);

  useEffect(() => {
    socket.on("geofence", (event) => {
      dispatch(getNotifGeoFence(event));
    });
    socket.on("wa-token", (event) => {
      dispatch(getToken(event));
    });
    socket.on("dashboard", (event) => {
      dispatch(getAllDashboard(event));
    });
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <NotistackProvider>
            <MotionLazyContainer>
              <ProgressBarStyle />
              <Settings />
              <ScrollToTop />
              <Router />
              {isAuthenticated && <DialogNotif />}
              {isAuthenticated && <DialogIdle />}
            </MotionLazyContainer>
          </NotistackProvider>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
