// import { sum, map, filter, uniqBy, reject } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// import { apiUrl } from "src/config";

// ----------------------------------------------------------------------

const initialState = {
  errorMessage: "",
  textError: "",
  datas: [],
  location: [],
};

const slice = createSlice({
  name: "device",
  initialState,
  reducers: {
    // LOADING
    startLoading(state, action) {
      state.textError = "loadingInit";
    },
    stopLoading(state) {
      state.textError = "";
    },
    getLocation(state, action) {
      state.location = action.payload;
    },

    // GET Datas

    getTextError(state, action) {
      state.textError = action.payload;
      // state.datas = action.payload;
    },
    getDatas(state, action) {
      state.textError = "200";
      state.datas = action.payload;
    },
    // ERROR
    hasError(state, action) {
      state.textError = action.payload.error;
      state.errorMessage = action.payload.message;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getDatas } = slice.actions;
// ----------------------------------------------------------------------

export function getAll() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/device`);
      //   console.log(response.data);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Request Failed" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getAllByKejatiForKit(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/device/kit/location/${id}`);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Request Failed" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getAllByKejati(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/device/kejati/${id}`);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Request Failed" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getAllByLocation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/device/user/${id}`);
      dispatch(slice.actions.getDatas(response.data));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Request Failed" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function postData(data, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // Promise.all()
      await axios.post(`/device`, {
        name: data.name,

        // serialNumber: data.serialNumber,
        imei: data.imei,
        noSimDevice: data.noSimDevice,
        location: data.location,
      });
      // const response = await axios.get(`/device`);
      const response = await axios.get(`/device/user/${userId}`);

      //   console.log(response.data);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-add"));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(slice.actions.hasError({ error: "400", message: error }));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}
export function editOne(data, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/device/${data.id}`, {
        name: data.name,
        // serialNumber: data.serialNumber,
        imei: data.imei,
        noSimDevice: data.noSimDevice,
        location: data.location,
      });
      // const response = await axios.get(`/device`);
      const response = await axios.get(`/device/user/${userId}`);

      //   console.log(response.data);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-edit"));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(slice.actions.hasError({ error: "400", message: error }));
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}
export function deleteOne(id, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/device/${id}`);
      // const response = await axios.get(`/device`);
      const response = await axios.get(`/device/user/${userId}`);

      //   console.log(response.data);
      dispatch(slice.actions.getDatas(response.data));
      dispatch(slice.actions.getTextError("200-delete"));
    } catch (error) {
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Failed to Delete" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getLocation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/user/location/${id}`);

      dispatch(slice.actions.getLocation(response.data));
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      if (error === "expired") {
        dispatch(
          slice.actions.hasError({ error: "401", message: "Session Expired" })
        );
      } else {
        dispatch(
          slice.actions.hasError({ error: "400", message: "Request Failed" })
        );
      }
    }
    dispatch(slice.actions.stopLoading());
  };
}
// ----------------------------------------------------------------------
