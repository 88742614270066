import { Box, Typography } from "@mui/material";

export default function DialogProfileDetail({ user }) {
  return (
    <Box display="flex" justifyContent="center">
      <Box
        sx={{
          py: 3,
          display: "grid",
          gridTemplateColumns: "46% 20px auto",
        }}
      >
        <Typography
          variant="subtitle2"
          component="div"
          sx={{ mb: 0.75, color: "text.disabled" }}
        >
          Name
        </Typography>
        <Typography
          variant="body1"
          component="div"
          sx={{ mx: 0.75, color: "text.disabled" }}
        >
          :
        </Typography>
        <Typography
          variant="body2"
          component="div"
          sx={{ mb: 0.75, color: "text.disabled" }}
        >
          {user.name}
        </Typography>
        <Typography
          variant="subtitle2"
          component="div"
          sx={{ mb: 0.75, color: "text.disabled" }}
        >
          Username
        </Typography>
        <Typography
          variant="body1"
          component="div"
          sx={{ mx: 0.75, color: "text.disabled" }}
        >
          :
        </Typography>
        <Typography
          variant="body2"
          component="div"
          sx={{ mb: 0.75, color: "text.disabled" }}
        >
          {user.username}
        </Typography>
        <Typography
          variant="subtitle2"
          component="div"
          sx={{ mb: 0.75, color: "text.disabled" }}
        >
          Role
        </Typography>
        <Typography
          variant="body1"
          component="div"
          sx={{ mx: 0.75, color: "text.disabled" }}
        >
          :
        </Typography>
        <Typography
          variant="body2"
          component="div"
          sx={{ mb: 0.75, color: "text.disabled" }}
        >
          {user.role}
        </Typography>
        <Typography
          variant="subtitle2"
          component="div"
          sx={{ mb: 0.75, color: "text.disabled" }}
        >
          Location
        </Typography>
        <Typography
          variant="body1"
          component="div"
          sx={{ mx: 0.75, color: "text.disabled" }}
        >
          :
        </Typography>
        <Typography
          variant="body2"
          component="div"
          sx={{ mb: 0.75, color: "text.disabled" }}
        >
          {user.location}
        </Typography>
      </Box>
    </Box>
  );
}
