import { useState, useEffect } from "react";
// @mui
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  MenuItem,
  Avatar,
  IconButton,
  Grid,
  Badge,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// components
import MenuPopover from "../components/MenuPopover";
import { IconButtonAnimate } from "../components/animate";
import useAuth from "../hooks/useAuth";
import { useSelector, useDispatch } from "../redux/store";
import { getIsNotif } from "../redux/slices/unit";
import DialogProfile from "../components/DialogProfile";
import DialogProfileDetail from "./DIalogProfileDetail";
import { apiUrl } from "../config";
// ----------------------------------------------------------------------

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);
  const [location, setLocation] = useState("");
  const { user, logout, role } = useAuth();
  const stateNotif = useSelector((state) => state.unit.notifGeoTest);
  const dispatch = useDispatch();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  // useEffect(() => {
  //   const locationParse =
  //     typeof user.location === "string"
  //       ? JSON.parse(user.location)
  //       : user.location;
  //   let locationTxt = "";
  //   locationParse?.forEach((value, index) => {
  //     if (index < locationParse.length - 1) {
  //       locationTxt += `${value}, `;
  //     } else {
  //       locationTxt += `${value}`;
  //     }
  //   });
  //   setLocation(locationTxt);
  // }, [user.location]);
  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <StyledBadge
          invisible={stateNotif.length <= 0}
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          variant="dot"
        >
          {user.profile && (
            <Avatar alt="user" src={`${apiUrl}/img/user/${user.profile}`} />
          )}
          {!user.profile && (
            <Avatar>
              <PersonIcon />
            </Avatar>
          )}
        </StyledBadge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {role.nameRole}
          </Typography>
          {/* <Box gridTemplateColumns="auto 20px auto" display="grid">
            <Typography variant="subtitle2" noWrap>
              Name
            </Typography>
            <Box display="flex" justifyContent="center">
              :
            </Box>
            <Typography variant="subtitle2" noWrap>
              {user.name}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
              Username
            </Typography>
            <Box display="flex" justifyContent="center">
              :
            </Box>
            
          </Box> */}
        </Box>
        <Divider sx={{ borderStyle: "dashed" }} />
        <Box padding="3px">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <IconButton
                onClick={() => {
                  dispatch(getIsNotif(true));
                  setOpen(null);
                }}
              >
                <Badge badgeContent={stateNotif.length} color="success">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Grid>
            <Grid item xs={3}>
              <IconButton
                onClick={() => {
                  setOpenProfile(true);
                }}
              >
                <AccountCircleIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />
        <MenuItem
          sx={{ m: 1 }}
          onClick={() => {
            logout();
          }}
        >
          Logout
        </MenuItem>
      </MenuPopover>
      <DialogProfile
        open={openProfile}
        size="xs"
        close={() => {
          setOpenProfile(false);
        }}
        user={{
          name: user.name || "",
          role: role.nameRole || "",
          profile: `${apiUrl}/img/user/${user.profile}`,
        }}
      >
        <Box paddingX={"10px"}>
          <DialogProfileDetail
            user={{
              name: user.name || "",
              role: role.nameRole || "",
              username: user.username,
              location: user.location,
            }}
          />
        </Box>
      </DialogProfile>
    </>
  );
}
