import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import DashboardMapLayout from "../layouts/dashboardMap";

import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// components
import LoadingScreen from "../components/LoadingScreen";
import GuestGuard from "../guards/GuestGuard";
import RoleBasedGuard from "../guards/RoleBasedGuard";
//
import useAuth from "../hooks/useAuth";
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

function getPath(role) {
  let path = "";
  if (role.kitAssignment) {
    path = "/kit-assignment";
  } else if (role.detectionKitManager) {
    path = "/detection-kit-manager";
  } else if (role.historyTrack) {
    path = "/history-track";
  } else if (role.geofence) {
    path = "/geofence";
  } else if (role.prisonerManager) {
    path = "/prisioner-manager";
  } else if (role.deviceManager) {
    path = "/device-manager";
  } else if (role.prosecutorManager) {
    path = "/prosecutor-manager";
  } else if (role.userManagement) {
    path = "/user-management";
  } else if (role.userRole) {
    path = "/user-role";
  } else if (role.globalSettings) {
    path = "/global-settings";
  } else {
    path = "/log";
  }
  return path;
}

export default function Router() {
  const { isAuthenticated, role } = useAuth();

  return useRoutes([
    {
      path: "/",
      element: (
        <Navigate to={isAuthenticated ? getPath(role) : "/auth"} replace />
      ),
    },
    {
      path: "auth",
      element: (
        <GuestGuard>
          <Auth />
        </GuestGuard>
      ),
    },
    {
      path: "/",
      element: (
        <RoleBasedGuard roleName="kitAssignment">
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [{ path: "dashboard", element: <Dashboard /> }],
    },
    {
      path: "/",
      element: (
        <RoleBasedGuard roleName="kitAssignment">
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [{ path: "kit-assignment", element: <MasterKit /> }],
    },
    {
      path: "/",
      element: (
        <RoleBasedGuard roleName="detectionKitManager">
          <DashboardMapLayout />
        </RoleBasedGuard>
      ),
      children: [
        { path: "detection-kit-manager", element: <DetectionKitManager /> },
      ],
    },
    {
      path: "/",
      element: (
        <RoleBasedGuard roleName="historyTrack">
          <DashboardMapLayout />
        </RoleBasedGuard>
      ),
      children: [{ path: "history-track", element: <HistoryTrack /> }],
    },
    {
      path: "/",
      element: (
        <RoleBasedGuard roleName="geofence">
          <DashboardMapLayout />
        </RoleBasedGuard>
      ),
      children: [{ path: "geofence", element: <Geofence /> }],
    },
    {
      path: "/",
      element: (
        <RoleBasedGuard roleName="prisonerManager">
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [{ path: "prisoner-manager", element: <PrisonerManager /> }],
    },
    {
      path: "/",
      element: (
        <RoleBasedGuard roleName="deviceManager">
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [{ path: "device-manager", element: <DeviceManager /> }],
    },
    // {
    //   path: "/",
    //   element: (
    //     <RoleBasedGuard roleName="prisonerManager">
    //       <DashboardLayout />
    //     </RoleBasedGuard>
    //   ),
    //   children: [{ path: "master-kit", element: <MasterKit /> }],
    // },
    {
      path: "/",
      element: (
        <RoleBasedGuard roleName="prosecutorManager">
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        { path: "prosecutor-manager", element: <ProsecutorManager /> },
      ],
    },
    {
      path: "/",
      element: (
        <RoleBasedGuard roleName="userManagement">
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [{ path: "user-management", element: <UserManagement /> }],
    },
    {
      path: "/",
      element: (
        <RoleBasedGuard roleName="userRole">
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [{ path: "user-role", element: <UserRole /> }],
    },
    {
      path: "/",
      element: (
        <RoleBasedGuard roleName="globalSettings">
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [{ path: "global-setting", element: <GlobalSetting /> }],
    },
    {
      path: "/",
      element: (
        <RoleBasedGuard roleName="attorneyManagement">
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [{ path: "jaksa-managament", element: <JaksaManagement /> }],
    },
    {
      path: "/",
      element: (
        <RoleBasedGuard roleName="licenseManager">
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [{ path: "license-manager", element: <LicenseManager /> }],
    },
    {
      path: "/",
      element: (
        <RoleBasedGuard roleName="log">
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [{ path: "log", element: <Log /> }],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const Auth = Loadable(lazy(() => import("../pages/Auth")));
// const KitAssignment = Loadable(lazy(() => import("../pages/KitAssignment")));
const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")));
const DetectionKitManager = Loadable(
  lazy(() => import("../pages/DetectionKitManager"))
);
const HistoryTrack = Loadable(lazy(() => import("../pages/HistoryTrack")));
const PrisonerManager = Loadable(
  lazy(() => import("../pages/PrisionerManager"))
);
const ProsecutorManager = Loadable(
  lazy(() => import("../pages/ProsecutorManager"))
);
const UserManagement = Loadable(lazy(() => import("../pages/UserManagement")));
const UserRole = Loadable(lazy(() => import("../pages/UserRole")));
const GlobalSetting = Loadable(lazy(() => import("../pages/GlobalSetting")));
const LicenseManager = Loadable(lazy(() => import("../pages/LicenseManager")));
const DeviceManager = Loadable(lazy(() => import("../pages/DeviceManager")));
const MasterKit = Loadable(lazy(() => import("../pages/MasterKit")));
const JaksaManagement = Loadable(
  lazy(() => import("../pages/JaksaManagement"))
);
const Geofence = Loadable(lazy(() => import("../pages/Geofence")));
const Log = Loadable(lazy(() => import("../pages/Log")));

const NotFound = Loadable(lazy(() => import("../pages/Page404")));
